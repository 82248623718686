.carousel_img{
    height: 48vw;
    width: 100%;
    object-fit: cover;
}

/* Mobile devices */
@media (max-width: 600px) {
.carousel_img{
      
}
  }
  
  /* Tablets */
  @media (min-width: 601px) and (max-width: 1024px) {
    .carousel_img{
      
    }
  }
  
  /* Desktops */
  @media (min-width: 1025px) {
    .carousel_img{
      
    }
  }