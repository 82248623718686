.acsss-logo{
    /* object-fit: fill; */
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 30%;
    min-height: 40px;
    min-width: 40px;
}
.appbar{
    background-color: red;
}
.typography{
    font-size: 1.075rem;
    line-height: 1.6;
}
@media (max-width: 600px) {
    .acss-title{
        font-weight: 300;
        font-size: 1rem;
       line-height: 2;
       letter-spacing: 0.00938em;
    }
    .break-line{
        display: none;
    }
  }
  @media (min-width: 00px) and (max-width: 1024px) {
    .acss-title{
        font-weight: 300;
        font-size: 1rem;
       line-height: 2;
       letter-spacing: 0.00938em;
    }
    .break-line{
        display: none;
    }
  }
 .nav-link-button:hover{
   background-color: #478d94;
  }
  .acss-title{
    font-weight: 600;
    margin: 0px;
    margin-right: 35px;
  }
  .acss-title:hover{
      cursor: pointer;
      
  }
  .navBar{
    position: sticky;
    top: 0;
    z-index: 999;
  }
  a.active{
    background-color: #478d94;
    
  }
 /*  */
 