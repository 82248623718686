/* slick-custom.css */

.slick-prev, .slick-next {
    font-size: 24px; /* Increase the size of the arrows */
    width: 40px; /* Increase the width */
    height: 40px; /* Increase the height */
    z-index: 1; /* Ensure arrows are on top of other elements */
  }
  
  .slick-prev {
    left: -25px; /* Position the previous arrow on the left */
  }
  
  .slick-next {
    right: -25px; /* Position the next arrow on the right */
  }
  
  .slick-prev::before, .slick-next::before {
    color: green; /* Change the arrow color */
  }
  
  /* Optional: Style arrows on hover */
  .slick-prev:hover, .slick-next:hover {
    color: black; /* Change color on hover */
  }
  