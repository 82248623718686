.bottom_nav_Component{
    /* background-color: gray; */
    width: 100vw;
}
.grid_item{
    
}
.typo_element{
    text-decoration: underline #478d94;
}
